/**
 * Takes a list of functions and mounts each by passing in the root HTMLElement
 *
 * @param root
 * @param actions
 */
export function mount (
  root: RootElement,
  ...actions: any[]
): GenericFunction<Promise<void>> {
  return async function (): Promise<void> {
    actions.forEach((action) => action(root))
  }
}
