import { Observable } from 'rxjs'

export interface Props {
  [key: string]: any
}

type Watchable = {
  target: Observable<any>
  actions: ((data: any) => any)[]
}

export type ComponentActions = {
  start: () => Promise<void>
  onload?: () => void
  onunload?: () => void
  watch?: Watchable[]
}

export type Component = (root: HTMLElement, props: any) => Promise<ComponentActions>

/**
 * Calls onLoad for each module allowing you to immediately invoke action when
 * the module is bootstrapped
 *
 * @param props
 * @param components
 * @returns
 */
function handleOnload (props: Props, components: Component[]) {
  components.forEach(async (component) => {
    const mod = await component(document as unknown as HTMLElement, props)
    mod.onload && mod.onload()
  })
}

function registerSubscribers (component: ComponentActions) {
  component.watch?.forEach(({ target, actions }: Watchable) => {
    return [...actions].forEach((action) => target.subscribe((data) => action(data)))
  })
}

/**
 *
 * @param element
 * @param components
 * @param props
 */
function loadModules (element: HTMLElement, components: Component[], props: Props) {
  components.forEach(async (component) => {
    const comp = await component(element, props)
    await comp.start()
    registerSubscribers(comp)
  })
}

/**
 * bootstrap
 *
 * Loads a module and calls the start function with props as its parameters
 * IMPORTANT: Lazy is very experimental, use at your own risk
 *
 * @param app
 * @param components
 * @param props
 */
export function bootstrap (app: string, components: Component[], props: Props): void {
  const containers = document.querySelectorAll(`[dk-component="${app}"]`)
  handleOnload(props, components)

  if (!containers) return

  containers.forEach((element) => {
    loadModules(element as HTMLElement, components, props)
  })
}

/**
 * bootstrapAll
 *
 * Takes a configuration object and bootstraps each entry.
 * in order to apply lazy evaluation to an entry add :lazy to the key. e.g. log-in:lazy.
 *
 * @param config
 * @param props
 */
export async function bootstrapAll<T> (config: Record<string, Component[]>, props: { [key in keyof T]: T[key] }) {
  const components = Object.keys(config).map((componentName) => {
    return bootstrap(componentName, config[componentName], props)
  })

  await Promise.all(components)
}
