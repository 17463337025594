import { mount } from '@dk/client/mount'
import { Splide } from '@splidejs/splide'
import type { ComponentActions } from '@dk/client/bootstrap'

export default async (root: RootElement): Promise<ComponentActions> => {
  const buildSplide = () => {
    const list = root.querySelector('.splide__list')
    if (list && list.childNodes.length >= 1) {
      new Splide(root as HTMLElement, {
        arrows: false,
        pagination: false,
        type: 'loop',
        gap: '20px',
        drag: 'free',
        snap: true,
        perPage: 4,
      }).mount()
    }
  }

  return {
    start: mount(root, buildSplide),
  }
}
