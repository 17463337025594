import { mount } from '@dk/client/mount'
import { Splide } from '@splidejs/splide'
import type { Components } from '@splidejs/splide'
import type { ComponentActions } from '@dk/client/bootstrap'

export default async (root: RootElement): Promise<ComponentActions> => {
  // https://splidejs.com/guides/extension/#example
  const slideNumber = (Splide: Splide, Components: Components) => {
    const { track } = Components.Elements
    let elem: HTMLElement

    const mount = () => {
      elem = document.createElement('div')
      elem.style.textAlign = 'center'
      elem.style.marginTop = '0.5em'
      elem.classList.add('splide__pages')

      const destination = track.parentElement!.querySelector('.splide__buttons')
      if (destination) {
        destination.appendChild(elem)
      } else {
        track.parentElement!.appendChild(elem)
      }

      update()
      Splide.on('move', update)
    }
    const update = () => {
      elem.textContent = `${Splide.index + 1}/${Splide.length}`
    }
    return {
      mount,
    }
  }

  const buildSplide = () => {
    const list = root.querySelector('.splide__list')
    if (list && list!.childNodes.length >= 1) {
      new Splide(<HTMLElement>root, {
        pagination: false,
        perPage: 1,
        perMove: 1,
        gap: 16,
      }).mount({
        slideNumber,
      })
    }
  }

  return {
    start: mount(root, buildSplide),
  }
}
