import { mount } from '@dk/client/mount'
import { Splide } from '@splidejs/splide'
import type { ComponentActions } from '@dk/client/bootstrap'

export default async (root: RootElement): Promise<ComponentActions> => {
  const buildSplide = () => {
    const list = root.querySelector('.splide__list')
    if (list && list.childNodes.length >= 1) {
      new Splide(root as HTMLElement, {
        arrows: false,
        pagination: true,
        type: 'loop',
        autoplay: true,
        perPage: 1,
        interval: 5000,
        speed: 800,
      }).mount()
    }
  }

  return {
    start: mount(root, buildSplide),
  }
}
